:root {
  --color-brand: #0a3850;
  --color-brand-dark: #303030;
  --color-brand-light: #007aa3;
  --color-brand-light-hover: #006282;

  --color-sidebar-link: #d4d4d4;
  --color-sidebar-icon: #00ace6;

  --color-admin-bar: var(--color-brand-dark);
  --font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  --bg-splash: #23aee3 url("../../images/v2-bg.svg");
}
